import { datadogRum } from '@datadog/browser-rum';
import { GatsbyBrowser } from 'gatsby';
import DD_OPTIONS from '../tng-common/scripts/data-dog-options';

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location, prevLocation }) => {
  if (!prevLocation && !location.hash) {
    // do not restore scroll position on page refresh without hash
    setTimeout(() => window.scrollTo({ top: 0, left: 0, behavior: 'instant' }), 0);
  }
};
export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  routerProps: { location }
}) => {
  window.history.scrollRestoration = 'manual';

  setTimeout(() => {
    if (location.hash) {
      // If hash (`/#something`), smooth scroll to that position
      const item = document.querySelector(`${location.hash}`);
      item?.scrollIntoView({ behavior: 'smooth' });
    } else {
      window.scrollTo(0, 0);
    }
  }, 0);

  // do not let gatsby do anything more
  return false;
};
declare global {
  interface Window {
    gatsbyHydrationComplete: boolean;
    ecs_vertical: string;
    ecs_category: string;
  }
}
export const onInitialClientRender = () => {
  // add custom event to window to signal t&t that React hydration is complete
  const event = new CustomEvent('gatsbyHydrationComplete', {
    bubbles: true,
    cancelable: false
  });
  window.dispatchEvent(event);
  window.gatsbyHydrationComplete = true;

  // run dataDog script
  if (process.env.GATSBY_ENV !== 'development' && datadogRum) {
    datadogRum.init(DD_OPTIONS);
    datadogRum.startSessionReplayRecording();
  }
};
